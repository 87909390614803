/**
 *  THIS FILE IS AUTOGENERATED. DO NOT EDIT THIS FILE DIRECTLY.
 */
export default {
	acceptQuoteV2: () => import("./mutations/acceptQuoteV2.json"),
	accountByTokenV2: () => import("./queries/accountByTokenV2.json"),
	accountV2: () => import("./queries/accountV2.json"),
	addImageUploadMeta: () => import("./mutations/addImageUploadMeta.json"),
	addLeadPicks: () => import("./mutations/addLeadPicks.json"),
	addProviderMediaPictures: () =>
		import("./mutations/addProviderMediaPictures.json"),
	addProviderMediaVideo: () => import("./mutations/addProviderMediaVideo.json"),
	affinitiesV2: () => import("./queries/affinitiesV2.json"),
	answersV2: () => import("./queries/answersV2.json"),
	answerV2: () => import("./queries/answerV2.json"),
	approveImageUpload: () => import("./mutations/approveImageUpload.json"),
	approveLead: () => import("./mutations/approveLead.json"),
	approveProvider: () => import("./mutations/approveProvider.json"),
	approveReview: () => import("./mutations/approveReview.json"),
	approveUploads: () => import("./mutations/approveUploads.json"),
	banProvider: () => import("./mutations/banProvider.json"),
	categoriesV2: () => import("./queries/categoriesV2.json"),
	categoryV2: () => import("./queries/categoryV2.json"),
	chatRoomAuthorizationV2: () =>
		import("./queries/chatRoomAuthorizationV2.json"),
	checkAccountStatus: () => import("./queries/checkAccountStatus.json"),
	checkAccountStatusByProviderId: () =>
		import("./queries/checkAccountStatusByProviderId.json"),
	citiesV2: () => import("./queries/citiesV2.json"),
	cityCategoryStatV2: () => import("./queries/cityCategoryStatV2.json"),
	cityServiceStatV2: () => import("./queries/cityServiceStatV2.json"),
	cityV2: () => import("./queries/cityV2.json"),
	clientByTokenV2: () => import("./queries/clientByTokenV2.json"),
	clientInboxV2: () => import("./queries/clientInboxV2.json"),
	clientNotificationByToken: () =>
		import("./queries/clientNotificationByToken.json"),
	clientNotificationPrivileged: () =>
		import("./queries/clientNotificationPrivileged.json"),
	clientPrivilegedV2: () => import("./queries/clientPrivilegedV2.json"),
	clientV2: () => import("./queries/clientV2.json"),
	cloneQuestionByCategory: () =>
		import("./mutations/cloneQuestionByCategory.json"),
	cloneQuestionByService: () =>
		import("./mutations/cloneQuestionByService.json"),
	completeProviderFunnelStep: () =>
		import("./mutations/completeProviderFunnelStep.json"),
	countCategoriesV2: () => import("./queries/countCategoriesV2.json"),
	countCitiesServicesStatsV2: () =>
		import("./queries/countCitiesServicesStatsV2.json"),
	countFAQSV2: () => import("./queries/countFAQSV2.json"),
	countLeadsByTokenV2: () => import("./queries/countLeadsByTokenV2.json"),
	countLeadsV2: () => import("./queries/countLeadsV2.json"),
	countMessagesV2: () => import("./queries/countMessagesV2.json"),
	countProviderServiceConfigurationsByTokenV2: () =>
		import("./queries/countProviderServiceConfigurationsByTokenV2.json"),
	countQuotesByProviderTokenV2: () =>
		import("./queries/countQuotesByProviderTokenV2.json"),
	countQuotesV2: () => import("./queries/countQuotesV2.json"),
	countReviewsPrivilegedV2: () =>
		import("./queries/countReviewsPrivilegedV2.json"),
	countReviewsV2: () => import("./queries/countReviewsV2.json"),
	countServicesV2: () => import("./queries/countServicesV2.json"),
	createAccountV2: () => import("./mutations/createAccountV2.json"),
	createAffinityV2: () => import("./mutations/createAffinityV2.json"),
	createAnswersV2: () => import("./mutations/createAnswersV2.json"),
	createAnswerV2: () => import("./mutations/createAnswerV2.json"),
	createCategoryV2: () => import("./mutations/createCategoryV2.json"),
	createCityServiceStatV2: () =>
		import("./mutations/createCityServiceStatV2.json"),
	createCityV2: () => import("./mutations/createCityV2.json"),
	createEmailQueueV2: () => import("./mutations/createEmailQueueV2.json"),
	createFAQV2: () => import("./mutations/createFAQV2.json"),
	createFunnelStepConfigurationTagV2: () =>
		import("./mutations/createFunnelStepConfigurationTagV2.json"),
	createLead: () => import("./mutations/createLead.json"),
	createMessageByClientTokenV2: () =>
		import("./mutations/createMessageByClientTokenV2.json"),
	createMessageByProviderTokenV2: () =>
		import("./mutations/createMessageByProviderTokenV2.json"),
	createOrUpdateProviderServiceConfigurationsByTokenV2: () =>
		import(
			"./mutations/createOrUpdateProviderServiceConfigurationsByTokenV2.json"
		),
	createProviderCredentialByTokenV2: () =>
		import("./mutations/createProviderCredentialByTokenV2.json"),
	createProviderCredentialsByTokenV2: () =>
		import("./mutations/createProviderCredentialsByTokenV2.json"),
	createProviderCreditCard: () =>
		import("./mutations/createProviderCreditCard.json"),
	createProviderFunnelStepConfigurationV2: () =>
		import("./mutations/createProviderFunnelStepConfigurationV2.json"),
	createProviderPassedLeadByTokenV2: () =>
		import("./mutations/createProviderPassedLeadByTokenV2.json"),
	createProviderSavedReplyByTokenV2: () =>
		import("./mutations/createProviderSavedReplyByTokenV2.json"),
	createProviderServiceConfigurationByTokenV2: () =>
		import("./mutations/createProviderServiceConfigurationByTokenV2.json"),
	createProviderServiceConfigurationsByTokenV2: () =>
		import("./mutations/createProviderServiceConfigurationsByTokenV2.json"),
	createQuestionPricingV2: () =>
		import("./mutations/createQuestionPricingV2.json"),
	createQuestionV2: () => import("./mutations/createQuestionV2.json"),
	createReportByTokenV2: () => import("./mutations/createReportByTokenV2.json"),
	createReviewByTokenV2: () => import("./mutations/createReviewByTokenV2.json"),
	createReviewCommentByClientTokenV2: () =>
		import("./mutations/createReviewCommentByClientTokenV2.json"),
	createReviewCommentByProviderTokenV2: () =>
		import("./mutations/createReviewCommentByProviderTokenV2.json"),
	createReviewPrivilegedV2: () =>
		import("./mutations/createReviewPrivilegedV2.json"),
	createReviewV2: () => import("./mutations/createReviewV2.json"),
	createServiceBusinessModelGroupV2: () =>
		import("./mutations/createServiceBusinessModelGroupV2.json"),
	createServiceSubscriptionV2: () =>
		import("./mutations/createServiceSubscriptionV2.json"),
	createServiceV2: () => import("./mutations/createServiceV2.json"),
	createSitemapV2: () => import("./mutations/createSitemapV2.json"),
	createStateV2: () => import("./mutations/createStateV2.json"),
	createTagV2: () => import("./mutations/createTagV2.json"),
	createTextQueueV2: () => import("./mutations/createTextQueueV2.json"),
	createUpload: () => import("./mutations/createUpload.json"),
	dataFeedBlast: () => import("./queries/dataFeedBlast.json"),
	dataFeedClientJobStatus: () =>
		import("./queries/dataFeedClientJobStatus.json"),
	dataFeedClientLeadApproved: () =>
		import("./queries/dataFeedClientLeadApproved.json"),
	dataFeedClientLeadDeclined: () =>
		import("./queries/dataFeedClientLeadDeclined.json"),
	dataFeedClientMessageReceived: () =>
		import("./queries/dataFeedClientMessageReceived.json"),
	dataFeedClientProDeclined: () =>
		import("./queries/dataFeedClientProDeclined.json"),
	dataFeedClientQuoteReceived: () =>
		import("./queries/dataFeedClientQuoteReceived.json"),
	dataFeedClientReviewRequest: () =>
		import("./queries/dataFeedClientReviewRequest.json"),
	dataFeedProAccountCreated: () =>
		import("./queries/dataFeedProAccountCreated.json"),
	dataFeedProApproachingWeeklyBudget: () =>
		import("./queries/dataFeedProApproachingWeeklyBudget.json"),
	dataFeedProAwardOffer: () => import("./queries/dataFeedProAwardOffer.json"),
	dataFeedProBlast: () => import("./queries/dataFeedProBlast.json"),
	dataFeedProBlastSaas: () => import("./queries/dataFeedProBlastSaas.json"),
	dataFeedProDirectLead: () => import("./queries/dataFeedProDirectLead.json"),
	dataFeedProInsightSummary: () =>
		import("./queries/dataFeedProInsightSummary.json"),
	dataFeedProMatchedLeadOpportunity: () =>
		import("./queries/dataFeedProMatchedLeadOpportunity.json"),
	dataFeedProMessageReceived: () =>
		import("./queries/dataFeedProMessageReceived.json"),
	dataFeedProRefundIssued: () =>
		import("./queries/dataFeedProRefundIssued.json"),
	dataFeedProReviewReceived: () =>
		import("./queries/dataFeedProReviewReceived.json"),
	dataFeedProTransactionFailed: () =>
		import("./queries/dataFeedProTransactionFailed.json"),
	dataFeedResetPassword: () => import("./queries/dataFeedResetPassword.json"),
	deactivateProviderServiceConfiguration: () =>
		import("./mutations/deactivateProviderServiceConfiguration.json"),
	declineQuoteV2: () => import("./mutations/declineQuoteV2.json"),
	declineQuoteV2Privileged: () =>
		import("./mutations/declineQuoteV2Privileged.json"),
	declineReview: () => import("./mutations/declineReview.json"),
	defaultStripePortalConfiguration: () =>
		import("./queries/defaultStripePortalConfiguration.json"),
	deleteFunnelStepConfiguration: () =>
		import("./mutations/deleteFunnelStepConfiguration.json"),
	deleteFunnelStepConfigurationTag: () =>
		import("./mutations/deleteFunnelStepConfigurationTag.json"),
	deleteLead: () => import("./mutations/deleteLead.json"),
	deleteProviderByToken: () => import("./mutations/deleteProviderByToken.json"),
	deleteProviderCreditCard: () =>
		import("./mutations/deleteProviderCreditCard.json"),
	deleteProviderCreditCardPrivileged: () =>
		import("./mutations/deleteProviderCreditCardPrivileged.json"),
	emailsQueuesV2: () => import("./queries/emailsQueuesV2.json"),
	FAQV2: () => import("./queries/FAQV2.json"),
	findOpenLead: () => import("./queries/findOpenLead.json"),
	findOrCreateProviderServiceConfiguration: () =>
		import("./mutations/findOrCreateProviderServiceConfiguration.json"),
	findOrCreateProviderServiceConfigurationPrivileged: () =>
		import(
			"./mutations/findOrCreateProviderServiceConfigurationPrivileged.json"
		),
	funnelsStepsConfigurationsTagsV2: () =>
		import("./queries/funnelsStepsConfigurationsTagsV2.json"),
	geoBoundaryByProvider: () => import("./queries/geoBoundaryByProvider.json"),
	geoip: () => import("./queries/geoip.json"),
	geoLocationByZip: () => import("./queries/geoLocationByZip.json"),
	geoLocationByZipAndAddress: () =>
		import("./queries/geoLocationByZipAndAddress.json"),
	getComboPagesPriority: () => import("./queries/getComboPagesPriority.json"),
	getProviderBiggestCity: () => import("./queries/getProviderBiggestCity.json"),
	getProviderBusinessLocationMarkerMap: () =>
		import("./queries/getProviderBusinessLocationMarkerMap.json"),
	getProviderClosestCity: () => import("./queries/getProviderClosestCity.json"),
	getProviderCombosMetricsByDate: () =>
		import("./queries/getProviderCombosMetricsByDate.json"),
	getProviderMetricsByDate: () =>
		import("./queries/getProviderMetricsByDate.json"),
	getProviderProfileImages: () =>
		import("./queries/getProviderProfileImages.json"),
	getProviderServicesMetricsByDate: () =>
		import("./queries/getProviderServicesMetricsByDate.json"),
	getReferralConfiguration: () =>
		import("./queries/getReferralConfiguration.json"),
	imageUploadAuthorization: () =>
		import("./queries/imageUploadAuthorization.json"),
	imageUploads: () => import("./queries/imageUploads.json"),
	isExact: () => import("./queries/isExact.json"),
	isExactMatch: () => import("./mutations/isExactMatch.json"),
	isProviderOnboardingFunnelComplete: () =>
		import("./queries/isProviderOnboardingFunnelComplete.json"),
	jwtVerify: () => import("./queries/jwtVerify.json"),
	leadApprovalRuleV2: () => import("./queries/leadApprovalRuleV2.json"),
	leadByTokenV2: () => import("./queries/leadByTokenV2.json"),
	leadsApprovalsRulesV2: () => import("./queries/leadsApprovalsRulesV2.json"),
	leadsByTokenV2: () => import("./queries/leadsByTokenV2.json"),
	leadsV2: () => import("./queries/leadsV2.json"),
	leadV2: () => import("./queries/leadV2.json"),
	login: () => import("./queries/login.json"),
	loginWithFb: () => import("./queries/loginWithFb.json"),
	loginWithGoogle: () => import("./queries/loginWithGoogle.json"),
	matchedLead: () => import("./queries/matchedLead.json"),
	matchedLeads: () => import("./queries/matchedLeads.json"),
	messagesByClientTokenV2: () =>
		import("./queries/messagesByClientTokenV2.json"),
	messagesByProviderTokenV2: () =>
		import("./queries/messagesByProviderTokenV2.json"),
	moveFunnelStepConfigurationDown: () =>
		import("./mutations/moveFunnelStepConfigurationDown.json"),
	moveFunnelStepConfigurationUp: () =>
		import("./mutations/moveFunnelStepConfigurationUp.json"),
	moveProviderMediaByKey: () =>
		import("./mutations/moveProviderMediaByKey.json"),
	paginateAccountsV2: () => import("./queries/paginateAccountsV2.json"),
	paginateAffinitiesV2: () => import("./queries/paginateAffinitiesV2.json"),
	paginateCategoriesV2: () => import("./queries/paginateCategoriesV2.json"),
	paginateCitiesServicesStatsV2: () =>
		import("./queries/paginateCitiesServicesStatsV2.json"),
	paginateCitiesV2: () => import("./queries/paginateCitiesV2.json"),
	paginateClientsV2: () => import("./queries/paginateClientsV2.json"),
	paginateFAQSV2: () => import("./queries/paginateFAQSV2.json"),
	paginateFunnelsStepsConfigurationsTagsV2: () =>
		import("./queries/paginateFunnelsStepsConfigurationsTagsV2.json"),
	paginateLeadsV2: () => import("./queries/paginateLeadsV2.json"),
	paginateProvidersFunnelsStepsConfigurationsV2: () =>
		import("./queries/paginateProvidersFunnelsStepsConfigurationsV2.json"),
	paginateProvidersV2: () => import("./queries/paginateProvidersV2.json"),
	paginateQueuesV2: () => import("./queries/paginateQueuesV2.json"),
	paginateQuotesByProviderTokenV2: () =>
		import("./queries/paginateQuotesByProviderTokenV2.json"),
	paginateQuotesV2: () => import("./queries/paginateQuotesV2.json"),
	paginateReportsV2: () => import("./queries/paginateReportsV2.json"),
	paginateReviewsV2: () => import("./queries/paginateReviewsV2.json"),
	paginateServiceBusinessModelGroupsV2: () =>
		import("./queries/paginateServiceBusinessModelGroupsV2.json"),
	paginateServiceSubscriptionsV2: () =>
		import("./queries/paginateServiceSubscriptionsV2.json"),
	paginateServicesV2: () => import("./queries/paginateServicesV2.json"),
	paginateStatesV2: () => import("./queries/paginateStatesV2.json"),
	partnersServicesV2: () => import("./queries/partnersServicesV2.json"),
	providerByTokenV2: () => import("./queries/providerByTokenV2.json"),
	providerCredentialV2: () => import("./queries/providerCredentialV2.json"),
	providerFunnelCurrentStep: () =>
		import("./queries/providerFunnelCurrentStep.json"),
	providerFunnelNextStep: () => import("./queries/providerFunnelNextStep.json"),
	providerInboxV2: () => import("./queries/providerInboxV2.json"),
	providerMetricQuoteViewPrivilegedV2: () =>
		import("./queries/providerMetricQuoteViewPrivilegedV2.json"),
	providerMetricQuoteViewV2: () =>
		import("./queries/providerMetricQuoteViewV2.json"),
	providerMetricReviewViewPrivilegedV2: () =>
		import("./queries/providerMetricReviewViewPrivilegedV2.json"),
	providerMetricReviewViewV2: () =>
		import("./queries/providerMetricReviewViewV2.json"),
	providerPassedLeadByTokenV2: () =>
		import("./queries/providerPassedLeadByTokenV2.json"),
	providerPassedLeadsByTokenV2: () =>
		import("./queries/providerPassedLeadsByTokenV2.json"),
	providerPrivilegedV2: () => import("./queries/providerPrivilegedV2.json"),
	providersAlertsByTokenV2: () =>
		import("./queries/providersAlertsByTokenV2.json"),
	providerSavedRepliesByTokenV2: () =>
		import("./queries/providerSavedRepliesByTokenV2.json"),
	providersCredentialsV2: () => import("./queries/providersCredentialsV2.json"),
	providerServiceConfigurationByTokenV2: () =>
		import("./queries/providerServiceConfigurationByTokenV2.json"),
	providerServiceConfigurationsByTokenV2: () =>
		import("./queries/providerServiceConfigurationsByTokenV2.json"),
	providerServiceConfigurationsV2: () =>
		import("./queries/providerServiceConfigurationsV2.json"),
	providerServiceConfigurationV2: () =>
		import("./queries/providerServiceConfigurationV2.json"),
	providersFunnelsStepsConfigurationsV2: () =>
		import("./queries/providersFunnelsStepsConfigurationsV2.json"),
	providersFunnelsStepsV2: () =>
		import("./queries/providersFunnelsStepsV2.json"),
	providersMedia: () => import("./queries/providersMedia.json"),
	providersPrivilegedV2: () => import("./queries/providersPrivilegedV2.json"),
	providersV2: () => import("./queries/providersV2.json"),
	providerV2: () => import("./queries/providerV2.json"),
	publishedContent: () => import("./queries/publishedContent.json"),
	publishedContentPages: () => import("./queries/publishedContentPages.json"),
	publishedCostGuides: () => import("./queries/publishedCostGuides.json"),
	publishedCourses: () => import("./queries/publishedCourses.json"),
	purchaseCreditPackage: () => import("./mutations/purchaseCreditPackage.json"),
	questionPricingByArrangement: () =>
		import("./queries/questionPricingByArrangement.json"),
	questionPricingV2: () => import("./queries/questionPricingV2.json"),
	questionsV2: () => import("./queries/questionsV2.json"),
	questionV2: () => import("./queries/questionV2.json"),
	queuesPrivilegedV2: () => import("./queries/queuesPrivilegedV2.json"),
	queuesV2: () => import("./queries/queuesV2.json"),
	quoteActionPrivilegedV2: () =>
		import("./queries/quoteActionPrivilegedV2.json"),
	quoteActionV2: () => import("./queries/quoteActionV2.json"),
	quoteByClientTokenV2: () => import("./queries/quoteByClientTokenV2.json"),
	quoteByProviderTokenV2: () => import("./queries/quoteByProviderTokenV2.json"),
	quotesActionsPrivilegedV2: () =>
		import("./queries/quotesActionsPrivilegedV2.json"),
	quotesActionsV2: () => import("./queries/quotesActionsV2.json"),
	quotesByProviderTokenV2: () =>
		import("./queries/quotesByProviderTokenV2.json"),
	quotesV2: () => import("./queries/quotesV2.json"),
	quoteV2: () => import("./queries/quoteV2.json"),
	redirectsV2: () => import("./queries/redirectsV2.json"),
	referralsByReferrerTokenV2: () =>
		import("./queries/referralsByReferrerTokenV2.json"),
	referralV2: () => import("./queries/referralV2.json"),
	refundQuote: () => import("./mutations/refundQuote.json"),
	refuseProvider: () => import("./mutations/refuseProvider.json"),
	removeAccountV2: () => import("./mutations/removeAccountV2.json"),
	removeAffinityV2: () => import("./mutations/removeAffinityV2.json"),
	removeAnswerV2: () => import("./mutations/removeAnswerV2.json"),
	removeCityServiceStatV2: () =>
		import("./mutations/removeCityServiceStatV2.json"),
	removeCityV2: () => import("./mutations/removeCityV2.json"),
	removeClientByTokenV2: () => import("./mutations/removeClientByTokenV2.json"),
	removeFAQV2: () => import("./mutations/removeFAQV2.json"),
	removeFunnelStepConfigurationTagV2: () =>
		import("./mutations/removeFunnelStepConfigurationTagV2.json"),
	removeLeadV2: () => import("./mutations/removeLeadV2.json"),
	removeProviderAlertByTokenV2: () =>
		import("./mutations/removeProviderAlertByTokenV2.json"),
	removeProviderFunnelStepConfigurationV2: () =>
		import("./mutations/removeProviderFunnelStepConfigurationV2.json"),
	removeProviderMediaPictureByKey: () =>
		import("./mutations/removeProviderMediaPictureByKey.json"),
	removeProviderMediaVideoByKey: () =>
		import("./mutations/removeProviderMediaVideoByKey.json"),
	removeProviderSavedReplyByTokenV2: () =>
		import("./mutations/removeProviderSavedReplyByTokenV2.json"),
	removeQuestionPricingV2: () =>
		import("./mutations/removeQuestionPricingV2.json"),
	removeQuestionsV2: () => import("./mutations/removeQuestionsV2.json"),
	removeReportV2: () => import("./mutations/removeReportV2.json"),
	removeReviewV2: () => import("./mutations/removeReviewV2.json"),
	removeStateV2: () => import("./mutations/removeStateV2.json"),
	removeUploads: () => import("./mutations/removeUploads.json"),
	resetPassword: () => import("./mutations/resetPassword.json"),
	reviewPrivilegedV2: () => import("./queries/reviewPrivilegedV2.json"),
	reviewsByClientTokenV2: () => import("./queries/reviewsByClientTokenV2.json"),
	reviewsByProviderTokenV2: () =>
		import("./queries/reviewsByProviderTokenV2.json"),
	reviewsPrivilegedV2: () => import("./queries/reviewsPrivilegedV2.json"),
	reviewsV2: () => import("./queries/reviewsV2.json"),
	reviewV2: () => import("./queries/reviewV2.json"),
	rotateProviderMediaPictureByKey: () =>
		import("./mutations/rotateProviderMediaPictureByKey.json"),
	search: () => import("./queries/search.json"),
	searchByAutoBill: () => import("./queries/searchByAutoBill.json"),
	searchByCityUri: () => import("./queries/searchByCityUri.json"),
	searchByComboUri: () => import("./queries/searchByComboUri.json"),
	searchByLead: () => import("./queries/searchByLead.json"),
	searchByLeadForNotifications: () =>
		import("./queries/searchByLeadForNotifications.json"),
	searchByMap: () => import("./queries/searchByMap.json"),
	searchByNearMeUri: () => import("./queries/searchByNearMeUri.json"),
	searchByProfileUri: () => import("./queries/searchByProfileUri.json"),
	searchByStateUri: () => import("./queries/searchByStateUri.json"),
	searchESMapping: () => import("./queries/searchESMapping.json"),
	searchForMatches: () => import("./queries/searchForMatches.json"),
	searchServiceAutoComplete: () =>
		import("./queries/searchServiceAutoComplete.json"),
	sendQuoteV2: () => import("./mutations/sendQuoteV2.json"),
	serviceBusinessModelGroupsV2: () =>
		import("./queries/serviceBusinessModelGroupsV2.json"),
	serviceBusinessModelGroupV2: () =>
		import("./queries/serviceBusinessModelGroupV2.json"),
	serviceSubscriptionsV2: () => import("./queries/serviceSubscriptionsV2.json"),
	serviceSubscriptionV2: () => import("./queries/serviceSubscriptionV2.json"),
	servicesV2: () => import("./queries/servicesV2.json"),
	serviceV2: () => import("./queries/serviceV2.json"),
	signupAsAdmin: () => import("./mutations/signupAsAdmin.json"),
	signupAsClient: () => import("./mutations/signupAsClient.json"),
	signupAsPro: () => import("./mutations/signupAsPro.json"),
	signupWithFb: () => import("./mutations/signupWithFb.json"),
	signupWithGoogle: () => import("./mutations/signupWithGoogle.json"),
	sitemapV2: () => import("./queries/sitemapV2.json"),
	socketCredentialsV2: () => import("./queries/socketCredentialsV2.json"),
	spamLead: () => import("./mutations/spamLead.json"),
	statesV2: () => import("./queries/statesV2.json"),
	stateV2: () => import("./queries/stateV2.json"),
	stripeCheckoutSessionUrl: () =>
		import("./queries/stripeCheckoutSessionUrl.json"),
	stripePortalSessionUrl: () => import("./queries/stripePortalSessionUrl.json"),
	subscriptionCheckoutSessionUrl: () =>
		import("./queries/subscriptionCheckoutSessionUrl.json"),
	subscriptionPortalConfiguration: () =>
		import("./queries/subscriptionPortalConfiguration.json"),
	subscriptionPortalSessionUrl: () =>
		import("./queries/subscriptionPortalSessionUrl.json"),
	subscriptionProducts: () => import("./queries/subscriptionProducts.json"),
	tagsV2: () => import("./queries/tagsV2.json"),
	textsQueuesV2: () => import("./queries/textsQueuesV2.json"),
	toggleHideProvider: () => import("./mutations/toggleHideProvider.json"),
	toggleHideProviderPrivileged: () =>
		import("./mutations/toggleHideProviderPrivileged.json"),
	transactionsByProvider: () => import("./queries/transactionsByProvider.json"),
	unbanProvider: () => import("./mutations/unbanProvider.json"),
	updateAccount: () => import("./mutations/updateAccount.json"),
	updateAccountByProviderTokenV2: () =>
		import("./mutations/updateAccountByProviderTokenV2.json"),
	updateAccountPrivileged: () =>
		import("./mutations/updateAccountPrivileged.json"),
	updateAccountV2: () => import("./mutations/updateAccountV2.json"),
	updateAffinityV2: () => import("./mutations/updateAffinityV2.json"),
	updateAllSmsProviderNotificationsByToken: () =>
		import("./mutations/updateAllSmsProviderNotificationsByToken.json"),
	updateAnswerV2: () => import("./mutations/updateAnswerV2.json"),
	updateCategoryV2: () => import("./mutations/updateCategoryV2.json"),
	updateCityServiceStatV2: () =>
		import("./mutations/updateCityServiceStatV2.json"),
	updateCityV2: () => import("./mutations/updateCityV2.json"),
	updateClientByTokenV2: () => import("./mutations/updateClientByTokenV2.json"),
	updateClientNotificationByToken: () =>
		import("./mutations/updateClientNotificationByToken.json"),
	updateClientV2: () => import("./mutations/updateClientV2.json"),
	updateDefaultStripePortalConfiguration: () =>
		import("./mutations/updateDefaultStripePortalConfiguration.json"),
	updateFAQV2: () => import("./mutations/updateFAQV2.json"),
	updateFunnelStepConfigurationTagV2: () =>
		import("./mutations/updateFunnelStepConfigurationTagV2.json"),
	updateJobStatus: () => import("./mutations/updateJobStatus.json"),
	updateLeadByTokenV2: () => import("./mutations/updateLeadByTokenV2.json"),
	updateLeadV2: () => import("./mutations/updateLeadV2.json"),
	updateMessagesByClientTokenV2: () =>
		import("./mutations/updateMessagesByClientTokenV2.json"),
	updateMessagesByProviderTokenV2: () =>
		import("./mutations/updateMessagesByProviderTokenV2.json"),
	updatePassword: () => import("./mutations/updatePassword.json"),
	updateProviderAlertByTokenV2: () =>
		import("./mutations/updateProviderAlertByTokenV2.json"),
	updateProviderBusinessHourByTokenV2: () =>
		import("./mutations/updateProviderBusinessHourByTokenV2.json"),
	updateProviderByTokenV2: () =>
		import("./mutations/updateProviderByTokenV2.json"),
	updateProviderCreditCard: () =>
		import("./mutations/updateProviderCreditCard.json"),
	updateProviderFunnelStepConfigurationV2: () =>
		import("./mutations/updateProviderFunnelStepConfigurationV2.json"),
	updateProviderMediaApproval: () =>
		import("./mutations/updateProviderMediaApproval.json"),
	updateProviderMediaCaption: () =>
		import("./mutations/updateProviderMediaCaption.json"),
	updateProviderMediaPictureHash: () =>
		import("./mutations/updateProviderMediaPictureHash.json"),
	updateProviderNotificationByToken: () =>
		import("./mutations/updateProviderNotificationByToken.json"),
	updateProviderPassedLeadByTokenV2: () =>
		import("./mutations/updateProviderPassedLeadByTokenV2.json"),
	updateProviderPrivilegedV2: () =>
		import("./mutations/updateProviderPrivilegedV2.json"),
	updateProvidersAlertsByTokenV2: () =>
		import("./mutations/updateProvidersAlertsByTokenV2.json"),
	updateProviderSavedReplyByTokenV2: () =>
		import("./mutations/updateProviderSavedReplyByTokenV2.json"),
	updateProviderServiceConfigurationByTokenV2: () =>
		import("./mutations/updateProviderServiceConfigurationByTokenV2.json"),
	updateProviderServiceConfigurationsByTokenV2: () =>
		import("./mutations/updateProviderServiceConfigurationsByTokenV2.json"),
	updateProviderServiceConfigurationV2: () =>
		import("./mutations/updateProviderServiceConfigurationV2.json"),
	updateProvidersPrivilegedV2: () =>
		import("./mutations/updateProvidersPrivilegedV2.json"),
	updateProviderSubscription: () =>
		import("./mutations/updateProviderSubscription.json"),
	updateProvidersV2: () => import("./mutations/updateProvidersV2.json"),
	updateProviderV2: () => import("./mutations/updateProviderV2.json"),
	updateQuestionPricingV2: () =>
		import("./mutations/updateQuestionPricingV2.json"),
	updateQuestionV2: () => import("./mutations/updateQuestionV2.json"),
	updateQuoteByClientTokenV2: () =>
		import("./mutations/updateQuoteByClientTokenV2.json"),
	updateQuoteByProviderTokenV2: () =>
		import("./mutations/updateQuoteByProviderTokenV2.json"),
	updateQuoteV2: () => import("./mutations/updateQuoteV2.json"),
	updateReferralV2: () => import("./mutations/updateReferralV2.json"),
	updateReportV2: () => import("./mutations/updateReportV2.json"),
	updateReviewV2: () => import("./mutations/updateReviewV2.json"),
	updateServiceBusinessModelGroupV2: () =>
		import("./mutations/updateServiceBusinessModelGroupV2.json"),
	updateServiceSubscriptionV2: () =>
		import("./mutations/updateServiceSubscriptionV2.json"),
	updateServiceV2: () => import("./mutations/updateServiceV2.json"),
	updateSitemapV2: () => import("./mutations/updateSitemapV2.json"),
	updateStateV2: () => import("./mutations/updateStateV2.json"),
	updateSubscriptionPortalConfiguration: () =>
		import("./mutations/updateSubscriptionPortalConfiguration.json"),
	updateTagV2: () => import("./mutations/updateTagV2.json"),
	updateUploadsV2: () => import("./mutations/updateUploadsV2.json"),
	updateUploadV2: () => import("./mutations/updateUploadV2.json"),
	uploadsMigrationStatus: () => import("./queries/uploadsMigrationStatus.json"),
	uploadsV2: () => import("./queries/uploadsV2.json"),
	uploadV2: () => import("./queries/uploadV2.json"),
};
