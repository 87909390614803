export const adjective = {
    "singular": "tutor",
    "plural": "tutors"
};

export const verb = {
    "singular": "tutoring"
};

export const job = {
    "singular": "session",
    "plural": "sessions"
};